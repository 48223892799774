import type { PiniaPluginContext } from 'pinia';

const storeOptions = {
    persist: {
        paths: undefined,
        storage: persistedState.localStorage,
        afterRestore: async (ctx: PiniaPluginContext) => {
            await ctx.store.refresh();
        },
    },
};

export default defineStore(
    'providers',
    () => {
        const isBusy = ref(false);
        const providers: Ref<TProviders> = ref<TProvider[]>([]);
        const hasError = ref(false);
        const notify = useNotificationsStore();

        async function fetchProviders() {
            try {
                if (isBusy.value) {
                    return;
                }

                isBusy.value = true;

                const { data, error } = await useFetch<TProviders>(
                    '/api/providers',
                    {
                        baseURL: useRuntimeConfig().public.apiBase,
                    }
                );

                if (error.value) {
                    if (!providers.value.length) {
                        hasError.value = true;

                        throw new Error('Cannot fetch providers');
                    }
                }
                hasError.value = false;
                providers.value = data.value || [];
            } catch {
                notify.error('Cannot fetch providers.');
            } finally {
                isBusy.value = false;
            }
        }

        return {
            providers,
            areProvidersLoading: isBusy,
            hasError,
            refresh: fetchProviders,
        };
    },
    storeOptions
);
